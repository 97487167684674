import {
    Box,
    Divider,
    Drawer,
    Stack,
    SvgIcon,
    useMediaQuery
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {SideNavItem} from "./SideNavIcon";
import {useLocation} from "react-router-dom";
import {Link} from "react-router-dom";
import {Logo} from "../../components/Logo";
import {ListAlt, VerifiedUser} from "@mui/icons-material";
import {useObservable} from "../../common/ui/ObservableHook";
import {authenticationStateHandler} from "../../pages/authentication/logic/AuthenticationStateHandler";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReceiptIcon from '@mui/icons-material/Receipt';


const items = [
    {
        title: 'Home',
        path: '/',
        icon: (
            <SvgIcon fontSize="small">
                <DashboardIcon/>
            </SvgIcon>
        )
    },
    {
        title: 'Meine Lizenzen',
        path: '/licenses',
        icon: (
            <SvgIcon fontSize="small">
                <ListAlt/>
            </SvgIcon>
        )
    },
    {
        title: 'Rechnungen',
        path: '/invoices',
        icon: (
            <SvgIcon fontSize="small">
                <ReceiptIcon/>
            </SvgIcon>
        )
    },
];
const adminItems = [
    {
        title: 'Alle Nutzer',
        path: '/admin/all-users',
        icon: (
            <SvgIcon fontSize="small">
                <SupervisorAccountIcon />
            </SvgIcon>
        )
    },
    {
        title: 'Alle Lizenzen',
        path: '/admin/all-licenses',
        icon: (
            <SvgIcon fontSize="small">
                <ListAlt/>
            </SvgIcon>
        )
    },
    {
        title: 'Lizenz erzeugen',
        path: '/admin/create-licenses',
        icon: (
            <SvgIcon fontSize="small">
                <ReceiptLongIcon/>
            </SvgIcon>
        )
    },
];


interface Props {
    open: boolean;
    onClose: () => void;
}

export function SideNav(props: Props) {
    const {open, onClose} = props;
    const pathname = useLocation().pathname;
    const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

    const profile = useObservable(authenticationStateHandler.profileObservable)

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                background: 'linear-gradient(180deg, rgba(238,240,249,1) 0%, rgba(242,237,227,1) 100%, rgba(0,212,255,1) 100%)'
            }}
        >
            <Box sx={{p: 2, pt: 4}}>
                <Box
                    component={Link}
                    to="/"
                    sx={{
                        display: 'inline-flex',
                        width: '100%'
                    }}
                >
                    <Logo/>
                </Box>
            </Box>
            <Divider sx={{borderColor: 'neutral.700'}}/>
            <Box
                component="nav"
                sx={{
                    flexGrow: 1,
                    px: 2,
                    py: 3
                }}
            >
                <Stack
                    component="ul"
                    sx={{
                        listStyle: 'none',
                        p: 0,
                        m: 0
                    }}
                >
                    {items.map((item) => {
                        const active = item.path ? (pathname === item.path) : false;

                        return (
                            <SideNavItem
                                active={active}
                                icon={item.icon}
                                key={item.title}
                                path={item.path}
                                title={item.title}
                            />
                        );
                    })}
                </Stack>
            </Box>

            {
                profile?.canSeeAdminUi &&
                <>
                    <Divider sx={{borderColor: 'neutral.700'}}/>

                    <Box
                        component="nav"
                        sx={{
                            flexGrow: 1,
                            px: 2,
                            py: 3
                        }}
                    >
                        <Stack
                            component="ul"
                            sx={{
                                listStyle: 'none',
                                p: 0,
                                m: 0
                            }}
                        >
                            {adminItems.map((item) => {
                                const active = item.path ? (pathname === item.path) : false;

                                return (
                                    <SideNavItem
                                        active={active}
                                        icon={item.icon}
                                        key={item.title}
                                        path={item.path}
                                        title={item.title}
                                    />
                                );
                            })}
                        </Stack>
                    </Box>
                </>
            }
        </Box>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.800',
                        color: 'common.white',
                        width: 280
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.800',
                    color: 'common.white',
                    width: 280
                }
            }}
            sx={{zIndex: (theme) => theme.zIndex.appBar + 100}}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
}
