import { Card, CardContent, Divider, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import { useState } from "react";
import dayjs from "dayjs";
import * as React from "react";
import UserInvoiceInformation from "../../invoices/model/UserInvoiceInformation";
import { EditInvoiceDetails } from "../../invoices/ui/EditInvoiceDetails";

export interface ConfigureLicenseState {
    eventName: string,
    numberDevices: string,
    startDate: dayjs.Dayjs | null,
    endDate: dayjs.Dayjs | null,
}

interface Props {
    continueButton: (state: ConfigureLicenseState, isValid: boolean) => React.JSX.Element
}

export function ConfigureLicenseComponent(props: Props) {
    const [state, setState] = useState<ConfigureLicenseState>({
        eventName: "",
        numberDevices: "",
        startDate: null,
        endDate: null,
    });

    const [invoiceInformationSet, setInvoiceInformationSet] = useState(false);
    const onInvoiceInformationLoaded = (information: UserInvoiceInformation | null) => {
        setInvoiceInformationSet(information != null);
    };

    let numberOfDays = 0;
    if (state.startDate && state.endDate) {
        numberOfDays = state.endDate.diff(state.startDate, "days", false) + 1
    }

    const numberOfDevices = parseInt(state.numberDevices);
    const isValid = state.eventName.length > 0 && numberOfDays > 0 && numberOfDevices > 0;

    return <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
            <Stack gap={2}>
                <TextField label="Name der Veranstaltung"
                    variant="outlined"
                    size="small"
                    value={state.eventName}
                    onChange={(e) => setState(
                        { ...state, eventName: e.target.value }
                    )}
                />
                <TextField label="Anzahl der Geräte"
                    variant="outlined"
                    size="small"
                    helperText="Alle Geräte über die Bestellungen aufgenommen werden (Geräte der Bedienungen, Theke, Bonverkauf, ...)"
                    value={state.numberDevices}
                    onChange={(e) => setState(
                        { ...state, numberDevices: e.target.value }
                    )}
                />

                <Typography mt={2}>Gebe hier den Zeitraum an, von wann bis wann die Veranstaltung
                    stattfindet:
                </Typography>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Stack direction="column" alignItems="center">
                            <Typography>Startdatum</Typography>
                            <DateCalendar
                                maxDate={state.endDate}
                                value={state.startDate}
                                onChange={(value) => setState(
                                    { ...state, startDate: value }
                                )}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack direction="column" alignItems="center">
                            <Typography>Enddatum</Typography>
                            <DateCalendar
                                minDate={state.startDate}
                                value={state.endDate}
                                onChange={(value) => setState(
                                    { ...state, endDate: value }
                                )}
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Typography variant="caption">
                    Die Lizenz kann ab 00:00 Uhr des Startdatums bis 06:00 Uhr am Tag nach dem Enddatum genutzt
                    werden.
                </Typography>
            </Stack>
        </Grid>

        <Grid item xs={12} md={3}>
            <LicensePriceSummaryCard
                numberOfDevices={numberOfDevices}
                numberOfDays={numberOfDays}
                onInvoiceInformationLoaded={onInvoiceInformationLoaded}
            />
            {props.continueButton(state, isValid && invoiceInformationSet)}
        </Grid>
    </Grid>
}


interface LicensePriceSummaryCardProps {
    numberOfDays: number,
    numberOfDevices: number,
    onInvoiceInformationLoaded: (information: UserInvoiceInformation | null) => void,
}

function LicensePriceSummaryCard(props: LicensePriceSummaryCardProps) {
    const price = props.numberOfDays * props.numberOfDevices * 5;

    return <Card variant="outlined">
        <CardContent>
            <Typography variant="subtitle1">Kosten</Typography>
            <Divider sx={{ mb: 2 }}></Divider>
            <Typography variant="body2">App-Lizenz <small>(5€ pro Tag pro Gerät)</small></Typography>
            <Typography variant="caption" ml={1}>{
                props.numberOfDevices ? props.numberOfDevices : "-"
            } {
                    props.numberOfDevices > 1 ? "Geräte" : "Gerät"
                }</Typography><br />
            <Typography variant="caption" ml={1}>{
                props.numberOfDays ? props.numberOfDays : "-"
            } {
                    props.numberOfDays > 1 ? "Tage" : "Tag"
                }</Typography>
            <Divider sx={{ mb: 2, mt: 1 }}></Divider>

            <Stack direction="row">
                <Typography variant="body2">Gesamt</Typography>
                <div style={{ flex: 1 }}></div>
                <Typography variant="body1">{
                    price ? price : "-"
                } €</Typography>
            </Stack>

            <Divider sx={{ mb: 2, mt: 1 }}></Divider>
            <Typography variant="body2">Rechnungsdetails</Typography>
            <EditInvoiceDetails onInvoiceInformationLoaded={props.onInvoiceInformationLoaded} />
        </CardContent>
    </Card>;
}