import {
    IconButton, Stack, styled,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, Typography,
} from "@mui/material";
import useSWR from "swr";
import { getFetcher } from "../../../hooks/ApiHooks";
import LoadingState from "../../../common/ui/LoadingState";
import dayjs from "dayjs";
import Invoice from "../Invoice";
import DownloadIcon from '@mui/icons-material/Download';
import { Link } from "react-router-dom";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.action.hover,
    fontWeight: "bolder",
    "> tr,th": {
        fontWeight: "bolder",
    }
}));


export default function InvoicesTable() {
    const {
        data: invoices,
        error,
        isLoading
    } = useSWR(
        `/api/users/current/invoices`,
        url => getFetcher<Invoice[]>(
            url,
            "Die Rechnungen konnten nicht geladen werden"
        ))

    return <>
        <TableContainer sx={{ width: '100%' }}>
            <Table>
                <TableHead>
                    <StyledTableRow>
                        <TableCell
                            style={{ minWidth: 30 }}>Rechnungsnummer</TableCell>
                        <TableCell
                            style={{ minWidth: 30 }}>Beschreibung</TableCell>
                        <TableCell
                            style={{ minWidth: 80 }}>Datum</TableCell>
                        <TableCell
                            style={{ minWidth: 80 }}>Download</TableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {
                        invoices?.map(l =>
                            <TableRow hover key={l.id}>
                                <TableCell>{l.invoiceNumber}</TableCell>
                                <TableCell>{l.comment}</TableCell>
                                <TableCell>{dayjs(l.invoiceDate).format("DD.MM.YYYY")}</TableCell>
                                <TableCell>
                                    <Link to={l.downloadUrl} target="_blank">
                                        <IconButton>
                                            <DownloadIcon />
                                        </IconButton>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        {
            invoices && invoices.length === 0 &&
            <Stack sx={{ mt: 10, width: "100%" }} alignItems="center">
                <Typography>Zukünftige Rechnungen werden hier angezeigt. </Typography>
            </Stack>
        }

        <LoadingState error={error} isLoading={isLoading} />
    </>
}
