import { useEffect, useState } from "react";
import EditInvoiceDialog from "./EditInvoiceDialog";
import useSWR from "swr";
import { getFetcher } from "../../../hooks/ApiHooks";
import ErrorBanner from "../../../common/ui/ErrorBanner";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import EditNoteIcon from '@mui/icons-material/EditNote';
import UserInvoiceInformation from "../model/UserInvoiceInformation";


interface EditInvoiceDetailsProps {
    onInvoiceInformationLoaded: (information: UserInvoiceInformation | null) => void,
}

export function EditInvoiceDetails(props: EditInvoiceDetailsProps) {
    const [showDialog, setShowDialog] = useState(false);

    const {
        data: invoiceInformation,
        error: loadInvoiceInformationError,
        isLoading,
        mutate,
    } = useSWR(
        `/api/users/current/invoiceDetails`,
        url => getFetcher<UserInvoiceInformation | null>(
            url,
            "Rechnungsinformationen konnten nicht geladen werden."
        ));

    const hasInvoiceInformationSet = invoiceInformation?.name &&
        invoiceInformation.street &&
        invoiceInformation.town &&
        invoiceInformation.zip;

    useEffect(() => {
        props.onInvoiceInformationLoaded(hasInvoiceInformationSet ? invoiceInformation : null)
    }, [invoiceInformation]);

    return <>
        <ErrorBanner error={loadInvoiceInformationError} />
        <EditInvoiceDialog
            invoiceInformation={hasInvoiceInformationSet ? invoiceInformation : null}
            open={showDialog}
            onCancel={() => setShowDialog(false)}
            onSaved={() => {
                setShowDialog(false);
                mutate();
            }} />
        {
            !isLoading &&
            <Box onClick={() => setShowDialog(true)}>
                <Stack direction="row" alignItems="center">
                    {
                        !hasInvoiceInformationSet &&
                        <Stack direction="row" color="error.light">
                            <Typography variant="caption" ml={1}>
                                Rechnungsdetails eingeben
                            </Typography>
                        </Stack>
                    }

                    {hasInvoiceInformationSet && <>
                        <Typography variant="caption" ml={1}>
                            {invoiceInformation?.name}<br />
                            {invoiceInformation?.street}<br />
                            {
                                (invoiceInformation?.additionalAddressInformation?.length ?? 0) > 0 &&
                                <>{invoiceInformation?.additionalAddressInformation}<br /></>
                            }
                            {invoiceInformation?.zip} {invoiceInformation?.town}
                        </Typography>
                    </>}

                    <div style={{ flex: 1 }} />

                    <IconButton size="large" onClick={() => setShowDialog(true)}>
                        <EditNoteIcon fontSize="medium" />
                    </IconButton>
                </Stack>

            </Box>
        }
    </>
}
