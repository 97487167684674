import {Button, Container, Stack, Typography} from "@mui/material";
import InvoicesTable from "./InvoicesTable";
import {Link} from "react-router-dom";


export default function InvoicesScreen() {
    return <Container>
        <Stack direction="column" alignItems="start">
            <Typography variant="h2">Rechnungen</Typography>
            <InvoicesTable/>
        </Stack>
    </Container>
}
