import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useApi } from "../../../hooks/ApiHooks";
import LoadingDialog from "../../../scaffold/ui/LoadingDialog";
import ErrorBanner from "../../../common/ui/ErrorBanner";
import UserInvoiceInformation from "../model/UserInvoiceInformation";


interface EditInvoiceDialogProps {
    invoiceInformation: UserInvoiceInformation | null;
    open: boolean;
    onCancel: () => void;
    onSaved: () => void;
}

export default function EditInvoiceDialog(props: EditInvoiceDialogProps) {
    const [invoiceInformation, setInvoiceInformation] = useState<UserInvoiceInformation>(props.invoiceInformation ?? {
        name: "",
        street: "",
        additionalAddressInformation: "",
        zip: "",
        town: "",
    });

    useEffect(() => {
        setInvoiceInformation(props.invoiceInformation ?? {
            name: "",
            street: "",
            additionalAddressInformation: "",
            zip: "",
            town: "",
        })
    }, [props.invoiceInformation, props.open])

    const canOk = invoiceInformation.name.trim().length > 0 &&
        invoiceInformation.street.trim().length > 0 &&
        invoiceInformation.zip.trim().length > 0 &&
        invoiceInformation.town.trim().length > 0

    const {
        doRequest: onSave,
        isLoading: isSaving,
        error: saveError
    } = useApi(
        "PATCH",
        `/api/users/current/invoiceDetails`,
        invoiceInformation,
        "Die Rechnungsinformationen konnten nicht aktualisiert werden.",
        () => props.onSaved()
    );

    return <>
        <Dialog open={props.open}
            onClose={() => { }}>
            <DialogTitle>
                Rechnungsadresse
            </DialogTitle>

            <DialogContent>
                <Stack direction="column" gap={1} sx={{ mt: 1 }}>
                    <TextField label="Verein / Firmenname / Name"
                        variant="outlined"
                        size="small"
                        value={invoiceInformation.name}
                        onChange={(e) => setInvoiceInformation({
                            ...invoiceInformation,
                            name: e.target.value
                        })}
                        autoFocus
                    />
                    <TextField label="Adresszusatz"
                        variant="outlined"
                        size="small"
                        value={invoiceInformation.additionalAddressInformation}
                        onChange={(e) => setInvoiceInformation({
                            ...invoiceInformation,
                            additionalAddressInformation: e.target.value
                        })}
                    />
                    <TextField label="Straße mit Hausnummer"
                        variant="outlined"
                        size="small"
                        value={invoiceInformation.street}
                        onChange={(e) => setInvoiceInformation({
                            ...invoiceInformation,
                            street: e.target.value
                        })}
                    />
                    <Stack direction="row" gap={1}>
                        <TextField
                            sx={{ flex: 1 }}
                            label="PLZ"
                            variant="outlined"
                            size="small"
                            value={invoiceInformation.zip}
                            onChange={(e) => setInvoiceInformation({
                                ...invoiceInformation,
                                zip: e.target.value
                            })}
                        />
                        <TextField
                            sx={{ flex: 3 }}
                            label="Stadt"
                            variant="outlined"
                            size="small"
                            value={invoiceInformation.town}
                            onChange={(e) => setInvoiceInformation({
                                ...invoiceInformation,
                                town: e.target.value
                            })}
                        />
                    </Stack>
                </Stack>
                <ErrorBanner error={saveError} />
            </DialogContent>

            <DialogActions>
                <Button onClick={onSave} disabled={!canOk}>
                    Ok
                </Button>
                <Button onClick={() => props.onCancel()}>
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
        <LoadingDialog open={isSaving} />
    </>
}