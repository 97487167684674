import {CircularProgress, Dialog, DialogContent, Stack, Typography} from "@mui/material";

interface Props {
    open: boolean,
    message?: string
}

export default function LoadingDialog(props: Props) {
    return <Dialog open={props.open}>
        <DialogContent>
            <Stack direction="row" alignItems="center">
                <CircularProgress/>
                <Typography ml={5}>{props.message ?? "Laden..."}</Typography>
            </Stack>
        </DialogContent>
    </Dialog>;
}
